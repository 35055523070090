import React from 'react'

const Name = () => {
  return (
    <section className="section">
      <h1>Arjun Bhandal</h1>
    </section>
  );
}

export default Name